import { getAction,getByIdAction,postAction,putAction,deleteByIdAction } from '@/api/common/request'
const baseURL = 'xun/work/xunPlan'

const listplansApi =(params)=>getAction(baseURL+"/lists",params);


const listplanApi =(params)=>getAction(baseURL+"/list",params);

const addplanApi=(params)=>postAction(baseURL+"/add",params);

const addRetTimeApi=(params)=>postAction(baseURL+"/addRetTime",params);

const editplanApi=(params)=>putAction(baseURL+"/edit",params);

const editBondStructureApi=(params)=>putAction(baseURL+"/editBondStructure",params);

const editRetTimeApi=(params)=>putAction(baseURL+"/editRetTime",params);

const deleteplanByIdApi=(params)=>deleteByIdAction(baseURL+'/deleteById',params);

const selectplanByIdApi=(params)=>getByIdAction(baseURL+'/selectById',params);

const getRestTimeListApi=(params)=>getAction(baseURL+'/getRestTimeList',params);


export{
    selectplanByIdApi,
    deleteplanByIdApi,
    editplanApi,
    addplanApi,
    addRetTimeApi,
    editRetTimeApi,
    listplanApi,
    getRestTimeListApi,
    listplansApi,
    editBondStructureApi
}
