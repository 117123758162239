<template>
  <!-- 作息 -->
  <div>
    <el-dialog
      :title="title"
      :before-close="handleClose"
      :visible.sync="dialog"
      direction="rtl"
      custom-class="demo-drawer"
      ref="drawer"
      width="26%"
    >
    <div>
      <el-form :model="addEditForm" ref="addEditForm">
        <!-- <el-row > -->
          <!-- <el-col :md="24" :sm="24"> -->
            <el-form-item label="模式" prop="model">
              <el-radio-group v-model="addEditForm.model">
                <el-radio-button
                  v-for="option in this.modelTypeEnum"
                  :label="option.key"
                  :value="option.key"
                  :key="option.key"
                  :disabled="editShow"
                  >{{ option.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
          <!-- </el-col> -->
        <!-- </el-row> -->
        <!-- <el-row > -->
          <!-- <el-col :md="24" :sm="24"> -->
            <el-form-item label="时间间隔" prop="type">
              <el-radio-group
                v-model="addEditForm.type"
                @change="changTimeTypeButton($event)"
              >
                <el-radio-button
                  v-for="option in this.restTimeList"
                  :label="option.key"
                  :value="option.key"
                  :key="option.key"
                  :disabled="editShow"
                  >{{ option.value }}</el-radio-button
                >
              </el-radio-group>
            </el-form-item>
          <!-- </el-col>
        </el-row> -->

        <!-- <el-row >
          <el-col :md="24" :sm="24"> -->
            <el-form-item label="备注" prop="remark">
              <el-input
                placeholder="请输入"
                v-model="addEditForm.remark"
                prop="remark"
                clearable
                @clear="handleQuery()"
                style="width: 80%;"
              />
            </el-form-item>
          <!-- </el-col> -->
        <!-- </el-row> -->

        <!-- <el-row > -->
          <!-- <el-col :md="12" :sm="8"> -->
            <el-form-item label="状态" prop="status">
              <el-radio v-model="addEditForm.status" label="1">草稿</el-radio>
              <el-radio v-model="addEditForm.status" label="2">正常</el-radio>
            </el-form-item>
          <!-- </el-col> -->
        <!-- </el-row> -->
      </el-form>
      <el-table :data="tableData" ref="tableData" height="500">
        <el-table-column
          align="center"
          label="序号"
          type="index"
          width="50"
        ></el-table-column>
        <el-table-column label="开始时间" align="center" width="100px">
          <template slot-scope="scope">
            <el-form :model="scope.row">
              <el-form-item>
                <el-input v-model="scope.row.startTime" disabled>{{
                  scope.row.startTime
                }}</el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="结束时间" align="center" width="100px">
          <template slot-scope="scope">
            <el-form :model="scope.row">
              <el-form-item>
                <el-input v-model="scope.row.endTime" disabled>{{
                  scope.row.endTime
                }}</el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="内容" align="center" width="170px">
          <template slot-scope="scope">
            <el-form :model="scope.row">
              <el-form-item>
                <el-input v-model="scope.row.content">{{
                  scope.row.content
                }}</el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column label="备注" align="center" width="150px">
          <template slot-scope="scope">
            <el-form :model="scope.row">
              <el-form-item>
                <el-input v-model="scope.row.remark">{{
                  scope.row.remark
                }}</el-input>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelForm">取 消</el-button>
        <el-button type="primary" @click="addFormButton()">确 定</el-button>
      </div>
    </div>
    </el-dialog>
  </div>
</template>
<script>
import { getWorkEnumList } from "@/api/commonApi/commonInternal/commonInternal";
import {
  getRestTimeListApi,
  addRetTimeApi,
  editRetTimeApi,
  selectplanByIdApi,
} from "@/api/work/plan/plan.js";
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      editShow: false,
      title: null,
      addEditForm: {
        model: "1",
      },
      timer: null,
      ids: null,
      planType: 1,
      tableData: [],
      restTimeList: [],
      modelTypeEnum: [],
      changTimeType: 1,
      radio: "",
      operatType: 1,
    };
  },
  methods: {
    show(id, type, planType) {
      this.dialog = true;
      this.planType = planType;
      this.getWorkEnumListButton();
      this.getModelTypeListButton();
      this.operatType = type;
      this.ids = id;
      console.log("====", this.ids);
      if (type == 2) {
        this.selectByIdButton();
        this.editShow = true;
        this.title = "编辑";
      }
      if (type == 1) {
        this.title = "新增";
      }
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("关闭")
        .then((_) => {
          this.cancelForm();
          this.dialog = false;
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      this.editShow = false;
      this.addEditForm = {};
      this.tableData = [];
      this.$parent.listPlanButton();
    },
    addFormButton() {
      this.addEditForm.xunRestListVOList = this.tableData;
      this.addEditForm.category = this.planType;
      if (this.operatType == 1) {
        addRetTimeApi(this.addEditForm).then((res) => {
          console.log("是", res);
          this.cancelForm();
        });
      } else {
        editRetTimeApi(this.addEditForm).then((res) => {
          this.cancelForm();
        });
      }
    },
    //查询详情
    selectByIdButton() {
      selectplanByIdApi(this.ids).then((res) => {
        this.addEditForm = res.result;
        this.tableData = res.result.xunPlanInfoReqVOS;
      });
    },
    addDialogClosed() {
      // 对整个表单进行重置，将所有字段值重置为初始值并移除校验结果
      this.$refs["addEditForm"].resetFields();
    },
    getWorkEnumListButton() {
      getWorkEnumList({ enumName: "RestTimeTypeEnum" }).then((res) => {
        this.restTimeList = res.result.slice(0, 3);
      });
    },
    getModelTypeListButton() {
      getWorkEnumList({ enumName: "ModelTypeEnum" }).then((res) => {
        this.modelTypeEnum = res.result;
      });
    },
    changTimeTypeButton(val) {
      this.changTimeListDataButton(val);
    },
    changTimeListDataButton(val) {
      getRestTimeListApi({ type: val }).then((res) => {
        this.tableData = res.result;
      });
    },
    getCurrentRow(row) {
      // 获取选中数据   row表示选中这一行的数据，可以从里面提取所需要的值
      console.log(row);
      this.id = row.id;
      this.templateSelection = row;
    },
  },
};
</script>
<style scoped>
</style>



